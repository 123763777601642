/**
 * Turn the virtual office tour into more of a video by making the user click
 * the tour to start it.  Otherwise, scroll is hijacked, which makes it hard to
 * navigate the page.
 *
 * Include in your gulpfile:
 *
 * `'../thesis/assets/javascripts/src/office_tour.js' `
 *
 * Initialize:
 *
 * ```
 * var officeTourSelector = '.office-tour.fragment';
 * if ($(officeTourSelector).length) {
 *   var officeTour = new OfficeTour(officeTourSelector);
 *   officeTour.enable();
 * }
 * ```
 *
 * @param {string} selector Wrapper class for office tour
 */
function OfficeTour(selector) {

  this.selector = selector;

  this.tourIsLoaded = function() {
    return $(this.selector).find('iframe').length > 0 ? true : false;
  };

  this.playOnClick = function() {
    $(document).on('click', this.selector + '.pause', function() {
      var $officeTour = $(this);
      var iframe = null;
      var poster = null;

      if (!this.tourIsLoaded) {
        var officeTourUrl = $officeTour.find('[data-iframe-src]').data('iframe-src');
        poster = $officeTour.find('[data-iframe-src="'+officeTourUrl+'"]')[0];

        iframe = document.createElement('iframe');
        iframe.setAttribute('allowfullscreen', '');
        iframe.setAttribute('frameborder', '0');
        iframe.src = officeTourUrl;
      }

      if (poster && iframe) {
        poster.parentNode.replaceChild(iframe, poster);
      }

      $officeTour.removeClass('pause');
      $officeTour.addClass('play');
    });
  };

  this.pauseOnLeave = function () {
    $(document).on('mouseleave', this.selector + '.play', function (event) {
      var $officeTour = $(this);

      if (event.buttons === 1) {
        // dragging mouse, don't pause yet
        $(document).one('mouseup', function (event) {
          // pause when releasing mouse
          $officeTour.removeClass('play');
          $officeTour.addClass('pause');
        });
      } else {
        $officeTour.removeClass('play');
        $officeTour.addClass('pause');
      }
    });
  };

  this.pauseOnTouchMove = function () {
    var $officeTour = $(this.selector);

    $(document).on('touchmove', function (event) {
      // dragging touch screen away from iframe
      $officeTour.removeClass('play');
      $officeTour.addClass('pause');
    });
  };

  this.handleTouchMove = function() {
    var $officeTour = $(this.selector);
    $officeTour.removeClass('play');
    $officeTour.addClass('pause');
  }

  this.enable = function () {
    this.playOnClick();
    this.pauseOnLeave();
    this.pauseOnTouchMove();
  };
}

jQuery(document).ready(function() {
	$('.wrap-images img:not(.img-plain)').wrap('<figure class="image-border"><span>');
});

$(function () {
  var officeTourSelector = '.office-tour';
  if ($(officeTourSelector).length) {
    $(officeTourSelector).addClass('pause');
    var officeTour = new OfficeTour(officeTourSelector);
    officeTour.enable();
  }
});

document.addEventListener('DOMContentLoaded', function() {
  LoopingVideo('video.looping-video');
});

var selectFields = document.querySelectorAll('.custom-select');
[].forEach.call(selectFields, function(selectField) {
  selectField.parentNode.classList.add("has-select");
});

/**
 * Controls when looping videos play/pause based on the video's position in the viewport.
 *
 * Suggested usage:
 *
 *   document.addEventListener('DOMContentLoaded', function() {
 *     LoopingVideo(selector);
 *   });
 *
 * @param {string} selector Selector for looping <video> elements
 */
function LoopingVideo(selector) {
  // Using [].slice.call() to convert the nodeList to an Array
  var loopingVideos = [].slice.call(document.querySelectorAll(selector));

  if (loopingVideos.length === 0) {
    return;
  }

  // iOS Safari automatically plays and pauses video
  // https://webkit.org/blog/6784/new-video-policies-for-ios/
  if (!navigator.userAgent.match(/iPhone|iPod|iPad/i)) {
    document.addEventListener('scroll', function() {
      // Window height
      var windowHeight = window.innerHeight;
      // Amount of video that can scroll in or out before play/pause)
      var tolerance = 0.5;

      loopingVideos.forEach(function(video) {
        // Video position
        var rect = video.getBoundingClientRect();
        // Y-axis tolerance
        var toleranceY = (rect.bottom - rect.top) * tolerance;

        // Play/pause video based on tolerance
        if (rect.bottom < toleranceY || windowHeight - rect.top < toleranceY) {
          !video.paused && video.pause();
        } else {
          video.paused && video.play();
        }
      });
    });
  }
}

var setupVideo = function ($video, videoType) {
  $('.controls .browse.next').removeClass('disabled');

  var is_firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  var me_mode = 'auto';
  if (is_firefox) {
    me_mode = 'auto_plugin';
  }
  new MediaElementPlayer($video, {
    type: videoType,
    mode: me_mode,
    startVolume: 0.8,
    AndroidUseNativeControls: true,
    iPhoneUseNativeControls: true,
    iPadUseNativeControls: true,
    pluginPath: '//d4xmq39929kw8.cloudfront.net/mediaelement-2.14/',
    plugins: ['flash'],
    flashName: 'flashmediaelement-cdn.swf'
  });
};

$(function () {
  var $video = $('#video-player');
  if (!$video.length) {
    return;
  }
  var videoType = $video.data('type');

  setupVideo($video, videoType);
});

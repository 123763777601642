(function($) {          
  $(document).ready(function(){                    
      $(window).scroll(function(){                          
          if ($(this).scrollTop() > 800) {
              $('#nav-on-scroll').fadeIn(500);
          } else {
              $('#nav-on-scroll').fadeOut(400);
          }
      });
  });
})(jQuery);